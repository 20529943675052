import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { NAVIGATION_ACTIONS } from '@store/app';
import { REVIEW_API_ACTIONS, selectReviewSubmitted, selectReviewUrl, selectUserAd } from '@store/digi.me';
import { PatientFederationReviewBannerComponent } from './patient-federation-review-banner.component';

@Component({
  standalone: true,
  selector: 'app-patient-federation-review-banner-container',
  templateUrl: './patient-federation-review-banner.container.html',
  imports: [SharedModule, PatientFederationReviewBannerComponent],
})
export class PatientFederationReviewBannerContainerComponent {
  readonly reviewUrl$ = this.store.select(selectReviewUrl);
  readonly userAd$ = this.store.select(selectUserAd);
  readonly isReviewSubmitted$ = this.store.select(selectReviewSubmitted);

  constructor(private readonly store: Store) {
    this.store.dispatch(REVIEW_API_ACTIONS.urlRequested());
  }

  reviewSubmitted(reviewUrl: string | undefined): void {
    if (!reviewUrl) {
      return;
    }

    this.store.dispatch(NAVIGATION_ACTIONS.reviewUrlNavigate({ reviewUrl: reviewUrl }));
  }
}
